<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('purchase.supplier.select-supplier')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc.stop="visible = false"
  >
    <DataTable
      dataKey="id"
      data-testid="supplier-dialog-list"
      ref="supplierSearchResultRef"
      :value="suppliers"
      :loading="loading"
      class="c-compact-datatable mb-6"
      v-model:selection="selectedSupplier"
      selectionMode="single"
      :rowClass="selectedLineRowClass"
      :stripedRows="true"
      @row-dblclick="onRowDblClick"
      @keydown.enter.prevent="onSupplierSelectedClicked"
    >
      <Column field="supplierNumber" :header="t('purchase.supplier.number')">
        <template #body="{ data, field, index }">
          <span :data-testid="`purchase-latest-order-line-supplier-number-${index}`">{{ data[field] }}</span>
        </template>
      </Column>

      <Column field="name" :header="t('purchase.supplier.name')">
        <template #body="{ data, field, index }">
          <span :data-testid="`purchase-latest-order-line-supplier-name-${index}`">{{ data[field] }}</span>
        </template>
      </Column>

      <Column field="companyAddress.city" :header="t('purchase.supplier.city')">
        <template #body="{ data, index }">
          <span :data-testid="`purchase-latest-order-line-supplier-city-${index}`">{{ data.companyAddress.city }}</span>
        </template>
      </Column>

      <Column field="phoneNumber" :header="t('purchase.supplier.phone')">
        <template #body="{ data, field, index }">
          <span :data-testid="`purchase-latest-order-line-supplier-phone-number-${index}`">{{ data[field] }}</span>
        </template>
      </Column>
    </DataTable>
    <div class="flex justify-end">
      <PrimeButton class="c-circular-button" @click="onCancelBtnClicked" data-testid="supplier-btn-cancel">
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.cancel") }}</span>
      </PrimeButton>
      <PrimeButton class="c-circular-button" @click="onSupplierSelectedClicked" data-testid="btn-add">
        <i class="pi pi-plus c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("purchase.supplier.select-supplier") }}</span>
      </PrimeButton>
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { useSupplier } from "@/api/supplier/SupplierService";
import { Supplier } from "@/models/supplier/Supplier";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import { computed, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  showDialog: boolean;
  supplierIds: string[];
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "supplierSelected", value: Supplier): void;
}>();

const suppliers = ref<Supplier[]>([]);
const selectedSupplier = ref<Supplier | null>(null);
const loading = ref<boolean>(false);
const supplierSearchResultRef = ref();

const { getSuppliersByIds } = useSupplier();
const { t } = useI18n();

const selectedLineRowClass = (data: Supplier) => {
  return ["c-search-result-row", { "c-search-result-row-selected": selectedSupplier.value?.id === data.id }];
};

const onCancelBtnClicked = (event: Event) => {
  event.stopPropagation();
  emit("update:showDialog", false);
};

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("supplierSelected", event.data as Supplier);
  emit("update:showDialog", false);
};

const onSupplierSelectedClicked = (event: Event) => {
  event.preventDefault();

  if (selectedSupplier.value == null) {
    return;
  }

  emit("supplierSelected", selectedSupplier.value);
  emit("update:showDialog", false);
};

const fetchSuppliersByIds = async () => {
  try {
    loading.value = true;
    suppliers.value = await getSuppliersByIds(props.supplierIds);
    nextTick(() => {
      focusFirstRow();
    });
  } finally {
    loading.value = false;
  }
};

const focusFirstRow = () => {
  return supplierSearchResultRef.value?.$el?.querySelector("tbody tr").focus();
};

onMounted(fetchSuppliersByIds);
</script>
