<template>
  <div class="c-layout-content c-layout-margin">
    <ConfirmPopup data-testid="confirm-popup" />
    <CustomToast />
    <PrimeVueLocaleHandler />
    <router-view v-slot="{ Component }">
      <KeepAlive include="PurchaseOrderSearch">
        <component :is="Component" />
      </KeepAlive>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { PrimeVueLocaleHandler } from "@cumulus/locale";
import { CustomToast } from "@cumulus/toast";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAuth } from "@cumulus/event-bus";

const { t } = useI18n();
const router = useRouter();

declare global {
  interface Date {
    toDateOnlyString(): string;
  }
}

Date.prototype.toDateOnlyString = function () {
  let date = this.getDate().toString();
  if (date.length === 1) date = "0" + date;
  let month = (this.getMonth() + 1).toString();
  if (month.length === 1) month = "0" + month;
  const year = this.getFullYear();
  return year + "-" + month + "-" + date;
};

router.beforeEach((to, _, next) => {
  if (to.meta?.title) {
    document.title = t(to.meta?.title as string);
  }
  next();
});

//Only running this code if the app is standalone
if (import.meta.env.VITE_APP_STANDALONE === "true") {
  const { getUser, isAuthenticated } = useAuth();
  onMounted(async () => (document.body.className = (await getUser()).getBodyCssClass(isAuthenticated.value)));
}
</script>
